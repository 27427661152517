import _ from 'lodash';
import { getCoreHTTPHeaders } from '@nerdwallet/nw-api-sdk/dist/headers';

import { addHasWpCookie } from './actions';
import { config } from './nw-api-sdk';
import { ServerRequest, ReduxStore, WpApiQuery } from './types';

const WP_LOGGED_IN_COOKIE_PREFIX = 'wordpress_logged_in_';
// Matches
// https://github.com/NerdWallet/edge-configs/blob/master/src/templates/location.conf.jinja#L133
// for cache bypassing.
const WP_COOKIES_PREFIXES = ['comment_author_', 'wordpress_', 'wp-postpass_'];

/**
 * Set flags based on request cookies.
 *
 * @param {Object} req Server request.
 * @param {Object} store Redux store.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types -- directive added automatically by Shepherd migration
export const setWpLoggedInStatus = (req: ServerRequest, store: ReduxStore) => {
  let hasWpCookie = false;
  const cookieKeys = Object.keys(_.get(req, 'cookies', {}));

  // Check to see if WP logged in cookie exists. Break early if so.
  cookieKeys.some((key) => {
    if (key.indexOf(WP_LOGGED_IN_COOKIE_PREFIX) > -1) {
      hasWpCookie = true;

      // Break out of the loop.
      return true;
    }

    // Continue the loop.
    return false;
  });

  // Synchronously write WP cookie flag.
  store.dispatch(addHasWpCookie(hasWpCookie));
};

/**
 * Get relevant NW/WP headers from initial server request.
 *
 * @param {Object} req Server request.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types -- directive added automatically by Shepherd migration
export const getRequestHeaders = (req: ServerRequest) => {
  // Manually include WP cookies, as they are not included in the core list in js-nw-api-sdk
  // @see https://github.com/NerdWallet/js-nw-api-sdk/blob/master/src/headers.js#L28
  const cookies = _.get(req, 'cookies', {});

  const wpCookies = _.pickBy(cookies, (value, key) =>
    _.some(WP_COOKIES_PREFIXES.map((prefix) => _.includes(key, prefix)))
  );

  return getCoreHTTPHeaders({
    config,
    cookies: wpCookies,
    serverRequest: req,
  });
};

/**
 * Propagate initial request headers on a node-wpapi query.
 *
 * @param {Object} query WP API query.
 * @param {Object} req Server request.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types -- directive added automatically by Shepherd migration
export const setWpApiRequestHeaders = function* (
  query: WpApiQuery,
  req: ServerRequest
) {
  const requestHeaders = yield getRequestHeaders(req);
  query.setHeaders(requestHeaders);
};
