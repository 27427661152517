import initNwApiSdk from '@nerdwallet/nw-api-sdk';
import createMarketplaceSdk from '@nerdwallet/nw-api-sdk/marketplace';
import appConfig from '@nerdwallet/app-config';
import { experiments } from './ab';

export const config = {
  applicationID: 'nerdWallet web',
  clientID: process.env.CLIENT_ID,
  cookieDomain: process.env.COOKIE_DOMAIN,
  identifyUrl: process.env.IDENTIFY_URL,
  identityBaseUrl: process.env.IDENTITY_BASE_URL,
  abUrl: process.env.AB_BASE_URL,
  abExperimentList: experiments,
};

const nwApiSDK = initNwApiSdk(config);

nwApiSDK.IdentityProfilesToInclude = [
  nwApiSDK.IdentitySDK.PROFILES.GLOBAL_PROFILE,
];

export default nwApiSDK;

export const marketplaceSdk = createMarketplaceSdk({
  ServiceSDK: nwApiSDK.ServiceSDK,
  clientID: config.clientID,
  marketplaceBaseUrl: appConfig.API.BASE_URLS.MARKETPLACE,
  wpApiBaseUrl: appConfig.API.BASE_URLS.WP_API,
});
