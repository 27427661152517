import { createStore, applyMiddleware, compose, Middleware } from 'redux';

import rootReducer from './reducers/index';

const DEV_IN_CLIENT =
  process.env.NODE_ENV === 'development' && process.browser === true;

export default (initialState: Record<string, unknown>) => {
  const middlewares: Array<Middleware> = [];

  const createStoreWithMiddleware = compose(
    applyMiddleware(...middlewares),
    DEV_IN_CLIENT && window.devToolsExtension
      ? window.devToolsExtension()
      : (f: any) => f
  )(createStore);
  // apply middlewares and enable devtools in local development
  const store = createStoreWithMiddleware(rootReducer, initialState);

  return store;
};
