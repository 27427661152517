import React from 'react';
import _ from 'lodash';

import { gql } from '@nerdwallet/apollo';
import baseStyles from '@nerdwallet/base-styles/classes';
import { Title } from '@nerdwallet/currency';
import { ElementImpression } from '@nerdwallet/nw-client-lib/tracking';
import { Impression as BedrockImpression } from '@nerdwallet/analytics/react/impression';

import { TEMPLATE_TYPES } from '@nerdwallet/structured-content/dist/components/post-list/constants';

import { getArticleTypeConfig } from '~/lib/article-utils';
import BlockGroup from '../block-group/block-group';

import styles from './related-links.module.less';
import { RelatedLinksFragment } from '~/generated-gql/generated-types';

interface Props {
  article: RelatedLinksFragment;
  showMore?: boolean;
  numItemsAboveFold?: number;
  maxItems?: number;
}

const RelatedLinks: React.GraphqlFC<Props> = ({
  article,
  showMore,
  numItemsAboveFold,
  maxItems,
}) => {
  const { relatedLinks: blockGroup } = article;

  return (
    <ElementImpression
      id="article-client-related-links"
      elementName="RelatedLinks"
      sectionCategory="sidebar"
    >
      <BedrockImpression
        eventType="content_element_impression"
        entityName="related_links"
        payload={{
          content_source: 'wp',
          content_id: parseInt(blockGroup.id, 10),
          content_id_type: 'post',
        }}
      >
        <div>
          <Title size="x-small" className={styles.headerVariant}>
            On a similar note...
          </Title>
          <BlockGroup
            blockGroup={blockGroup}
            className={baseStyles('margin-bottom-2')}
            wpPostListLimit={maxItems}
            template={
              showMore
                ? TEMPLATE_TYPES.EXPANDABLE_LIST
                : TEMPLATE_TYPES.IMAGE_LIST
            }
            templateConfig={
              showMore ? { showMore: true, numItemsAboveFold } : undefined
            }
            excludeArticleId={parseInt(article.id, 10)}
          />
        </div>
      </BedrockImpression>
    </ElementImpression>
  );
};

RelatedLinks.defaultProps = {
  showMore: false,
  numItemsAboveFold: 3,
  maxItems: 4,
};

RelatedLinks.fragment = gql`
  fragment RelatedLinks on Article {
    id
    articleType
    relatedLinks {
      ...BlockGroup
    }
    primaryCategory {
      name
      link
    }
  }
  ${BlockGroup.fragment}
`;

RelatedLinks.shouldShow = (article) => {
  const articleTypeConfig = getArticleTypeConfig(article?.articleType);
  const blockGroup = article?.relatedLinks;
  return _.get(articleTypeConfig, 'hasRelatedLinks', false) && !!blockGroup;
};

export default RelatedLinks;
