import React, { useRef, useEffect, useState, useCallback } from 'react';
import classes from '@nerdwallet/base-styles/classes';

interface Props {
  children: React.ReactNode;
  offset: number | string;
  fadeDistance: number;
}
/**
 * A sticky container that fades in, sticks, and fades out before is leaves the screen.
 * Required what scroll container, that this element is sticky within, be positioned
 */
const FadeContainer: React.FC<Props> = ({
  children,
  offset = 0,
  fadeDistance = 450,
}) => {
  const ref = useRef<HTMLDivElement>();
  const [offsetPx, setOffsetPx] = useState(0);
  const [offsetTop, setOffsetTop] = useState(Infinity);
  const [scrollPositionBottom, setScrollPositionBottom] = useState(0);

  const updateScrollTop = useCallback(() => {
    window.requestAnimationFrame(() => {
      setOffsetTop(ref.current?.getBoundingClientRect()?.top ?? 0);
      setScrollPositionBottom(
        ((ref.current?.offsetParent as HTMLElement)?.offsetHeight -
          ref.current?.offsetTop -
          ref.current?.offsetHeight -
          fadeDistance) *
          -1
      );
    });
  }, [fadeDistance]);

  const onLoaded = useCallback(() => {
    const offsetNum = parseInt(window.getComputedStyle(ref.current).top, 10);
    setOffsetPx(offsetNum);
  }, []);

  useEffect(() => {
    onLoaded();
    document.addEventListener('DOMContentLoaded', onLoaded);
    window.addEventListener('load', onLoaded);
    window.addEventListener('scroll', updateScrollTop);
    window.addEventListener('resize', updateScrollTop);

    return () => {
      document.removeEventListener('DOMContentLoaded', onLoaded);
      window.removeEventListener('load', onLoaded);
      window.removeEventListener('scroll', updateScrollTop);
      window.removeEventListener('resize', updateScrollTop);
    };
  }, [updateScrollTop, onLoaded]);

  const scrollPositionTop = (offsetTop - offsetPx - fadeDistance) * -1;

  const scrollTopOpacity = Math.max(
    Math.min(scrollPositionTop / fadeDistance, 1),
    0
  );

  const scrollBottomOpacity = Math.max(
    Math.min(1 - scrollPositionBottom / fadeDistance, 1),
    0
  );

  const opacity = Math.min(scrollTopOpacity, scrollBottomOpacity);

  return (
    <div
      ref={ref}
      style={{ opacity, top: offset }}
      className={classes('position-sticky')}
    >
      {children}
    </div>
  );
};

export default FadeContainer;
