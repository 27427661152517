import React from 'react';
import { Redirect, RedirectProps, Route } from 'react-router';
import PropTypes from 'prop-types';

/**
 * Status code route.
 *
 * Intended to be used solely in conjunction with React Router.
 * This route returns a status code using React Router's `routerContext`.
 *
 * @param {number} code Status code to return.
 */
export const StatusCode: React.FC<{ code: number }> = ({ code }) => (
  <Route
    render={({ staticContext }) => {
      if (staticContext) {
        staticContext.statusCode = code;
      }

      return null;
    }}
  />
);

StatusCode.propTypes = {
  /** Status code to pass. */
  code: PropTypes.number.isRequired,
};

/**
 * Not found (404) route.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types -- directive added automatically by Shepherd migration
export const NotFound = () => <StatusCode code={404} />;

/**
 * Unauthorized (401) route.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types -- directive added automatically by Shepherd migration
export const Unauthorized = () => <StatusCode code={401} />;

/**
 * Redirect (3xx) route.
 *
 * @param {number} code Status code to return.
 * @param {string} to Target redirect URL.
 */
export const RedirectWithStatusCode: React.FC<
  RedirectProps & { code: number }
> = ({ code, ...props }) => (
  <Route
    render={({ staticContext }) => {
      // There is no `staticContext` on the client, so
      // we need to guard against that here.
      if (staticContext) {
        staticContext.statusCode = code;
      }

      return <Redirect {...props} />;
    }}
  />
);

RedirectWithStatusCode.defaultProps = {
  code: 302,
};
