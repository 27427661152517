import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import classes from '@nerdwallet/base-styles/classes';
import { gql } from '@nerdwallet/apollo';

import { decode } from '../../../lib/render-utils';
import AuthorCard from './author-card';
import {
  AuthorBoxFragment,
  AuthorDetailsFragment,
} from '~/generated-gql/generated-types';

const SINGLE_AUTHOR_HEADING = 'About the author:';
const MULTIPLE_AUTHOR_HEADING = 'About the authors:';

interface Props {
  article: AuthorBoxFragment;
  className?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types -- directive added automatically by Shepherd migration
const AuthorBox = ({ article, className }: Props) => {
  const { authors } = article;

  if (_.isEmpty(authors)) {
    return null;
  }

  const validAuthors = authors.filter(
    (author) => author.name && author.tagline
  );
  if (validAuthors.length === 0) {
    return null;
  }

  // Ported from nw-wp-api/index -- legacy mapping
  const mapAuthor = (author: AuthorDetailsFragment) => {
    const mappedAuthor = {
      name: author.name,
      slug: author.slug,
      image: author?.avatarUrls?.[0].url ?? '',
      bio: decode(author.tagline),
      fullPageUrl: author.link,
      socialMediaLinks: [
        {
          url: !_.isEmpty(author.twitter)
            ? `https://twitter.com/${author.twitter}`
            : '',
          type: 'twitter',
        },
        {
          url: !_.isEmpty(author.contactEmail)
            ? `mailto:${author.contactEmail}`
            : '',
          type: 'email',
        },
        {
          url: author.facebook,
          type: 'facebook',
        },
      ],
    };

    mappedAuthor.socialMediaLinks = mappedAuthor.socialMediaLinks.filter(
      (channel) => !_.isEmpty(channel.url)
    );
    return mappedAuthor;
  };

  const transformedAuthors = validAuthors.map(mapAuthor);

  const heading =
    transformedAuthors.length > 1
      ? MULTIPLE_AUTHOR_HEADING
      : SINGLE_AUTHOR_HEADING;

  return (
    <aside>
      <div
        className={classNames([
          'author-box',
          classes('margin-bottom-4'),
          className,
        ])}
        itemScope
        itemProp="author"
        itemType="http://schema.org/Person"
      >
        {transformedAuthors.map((props, index) => (
          <AuthorCard
            {...props}
            key={props?.name}
            // if there's more than one author, only show heading on first one
            heading={index === 0 ? heading : null}
            className={classes('padding-top-2')}
          />
        ))}
      </div>
    </aside>
  );
};

const AuthorDetails = gql`
  fragment AuthorDetails on Author {
    avatarUrls(sizes: [SIZE_96]) {
      url
    }
    contactEmail
    facebook
    name
    link
    slug
    tagline
    twitter
  }
`;

AuthorBox.fragment = gql`
  fragment AuthorBox on Article {
    authors {
      ...AuthorDetails
    }
  }
  ${AuthorDetails}
`;

AuthorBox.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  article: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default AuthorBox;
