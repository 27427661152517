import React from 'react';
import _ from 'lodash';
import queryString from 'query-string';
import { gql } from '@nerdwallet/apollo';
import hoistNonReactStatics from 'hoist-non-react-statics';

import { getNerdlayoutDynamicOptions } from '@nerdwallet/wp-api/utils/meta-data';
import { getArticleSchema } from '@nerdwallet/json-ld/mappings/wp/article';

import AuthorBox from '~/client/components/author-box/author-box';
import ContentPageHeader from '~/client/components/content-page-header/content-page-header';
import Methodology from '~/client/components/methodology/methodology';
import HouseAd from '~/client/components/house-ad/house-ad';
import NextSteps from '~/client/components/next-steps/next-steps';
import RelatedLinks from '~/client/components/related-links/related-links';
import AdvertiserDisclosure from '~/client/components/advertiser-disclosure';
import EditorialStandards from '~/client/components/editorial-standards';
import Breadcrumb from '~/client/containers/article-container/breadcrumb';
import { getAbTestConfig, getArticleCanonicalUrl } from '~/lib/article-utils';
import {
  withArticle,
  withArticleFork,
  ArticleQueryVariables,
  ArticleForkQueryVariables,
  ArticleProps,
} from '~/generated-gql/generated-types';

const articleFragment = gql`
  fragment articleFragment on Article {
    # fields needed for article templates
    id
    isPublished
    articleType
    title
    seo {
      extraJsonLd
    }
    slug
    pageTaxonomy
    content
    link
    showEditorialDisclaimer
    showAuthorBox
    contentClusters
    hideFeaturedImage
    # Give it a different name so it doesn't conflict with featuredMedia coming from a fragment
    featuredImage: featuredMedia {
      id
      altText

      mediaDetails {
        id
        sizes(
          sizes: [
            "bb-article"
            "primary-xxxl"
            "primary-xxl"
            "primary-xl"
            "primary-l"
            "primary-m"
            "primary-s"
            "primary-xs"
            "primary-xxs"
            "full"
          ]
        ) {
          sourceUrl
          width
          height
          size
        }
      }
    }
    ...NextSteps
    ...RelatedLinks
    ...AuthorBox
    ...ContentPageHeader
    ...Methodology
    ...HouseAd
    ...getArticleCanonicalUrl
    ...getNerdlayoutDynamicOptions
    ...getArticleSchema
    ...getAbTestConfig
    ...AdvertiserDisclosure
    ...EditorialStandards
    ...Breadcrumb
  }
  ${AuthorBox.fragment}
  ${ContentPageHeader.fragment}
  ${Methodology.fragment}
  ${NextSteps.fragment}
  ${RelatedLinks.fragment}
  ${HouseAd.fragment}
  ${getArticleCanonicalUrl.fragment}
  ${getNerdlayoutDynamicOptions.fragment}
  ${getArticleSchema.fragment}
  ${getAbTestConfig.fragment}
  ${AdvertiserDisclosure.fragment}
  ${EditorialStandards.fragment}
  ${Breadcrumb.fragment}
`;
export const articleQuery = gql`
  query article(
    $identifier: ID!
    $wpnonce: ID
  ) {
    article(input: {
      identifier: $identifier
      wpnonce: $wpnonce
    }) {
      ...articleFragment
    }
    ${articleFragment}
  }
`;
export const articleForkQuery = gql`
  query articleFork(
    $identifier: ID!
    $wpnonce: String
    $forkPreview: Boolean
    $forkIdentifier: Int!
    $forkNonce: String
  ) {
    articleFork(input: {
      identifier: $identifier
      wpnonce: $wpnonce
      forkPreview: $forkPreview
      forkIdentifier: $forkIdentifier
      forkNonce: $forkNonce
    }) {
      ...articleFragment
    }
    ${articleFragment}
  }
`;

const gqlOptions = {
  options: (
    props: any
  ): {
    variables: ArticleQueryVariables;
    errorPolicy: any; // TODO-TS: replace with type ErrorPolicy
    ssr?: boolean;
  } => {
    const queryParams =
      queryString.parse(_.get(props, 'location.search')) || {};

    return {
      variables: {
        identifier: _.get(props, 'match.params.slug'),
        // eslint-disable-next-line no-underscore-dangle
        wpnonce: queryParams._wpnonce,
      },
      // https://www.apollographql.com/docs/react/data/error-handling/#error-policies
      errorPolicy: 'all',
      ssr: !_.has(queryParams, 'dev'),
    };
  },
};

const gqlOptionsFork = {
  options: (
    props: any
  ): {
    variables: ArticleForkQueryVariables;
    errorPolicy: any; // TODO-TS: replace with type ErrorPolicy
    ssr?: boolean;
  } => {
    const queryParams =
      queryString.parse(_.get(props, 'location.search')) || {};

    return {
      variables: {
        identifier: _.get(props, 'match.params.slug'),
        // eslint-disable-next-line no-underscore-dangle
        wpnonce: queryParams._wpnonce,
        forkPreview: queryParams.fork_preview === 'true',
        forkIdentifier: parseInt(queryParams.fork_id, 10),
        forkNonce: queryParams.fork_nonce,
      },
      // https://www.apollographql.com/docs/react/data/error-handling/#error-policies
      errorPolicy: 'all',
      ssr: !_.has(queryParams, 'dev'),
    };
  },
};

// eslint-disable-next-line @typescript-eslint/ban-types -- directive added automatically by Shepherd migration
export default <P extends object>(
  Component: React.ComponentType<P>
): React.ComponentType<P & ArticleProps> => {
  const WithArticle = withArticle(gqlOptions)(Component);
  const WithArticleFork = withArticleFork(gqlOptionsFork)(Component);
  const WrappedComponent = (props: any) => {
    const queryParams =
      queryString.parse(_.get(props, 'location.search')) || {};

    const forkPreview = queryParams.fork_preview;
    const forkId = queryParams.fork_id;
    const forkNonce = queryParams.fork_nonce;

    const isFork = forkPreview && forkId && forkNonce;

    if (isFork) {
      return <WithArticleFork {...props} />;
    }
    return <WithArticle {...props} />;
  };
  WrappedComponent.displayName = `withArticleQuery(${
    Component.displayName || Component.name || 'Component'
  })`;
  hoistNonReactStatics(WrappedComponent, Component);
  return WrappedComponent;
};
