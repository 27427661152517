import React from 'react';

import { gql } from '@nerdwallet/apollo';
import { EditorialStandards as NWEditorialStandards } from '@nerdwallet/currency';
import { EditorialStandardsFragment } from '~/generated-gql/generated-types';

interface Props {
  article: EditorialStandardsFragment;
  className: string;
}
const EditorialStandards: React.GraphqlFC<Props> = ({ article, ...props }) => {
  const { showEditorialDisclaimer, disclaimerType } = article;

  if (!showEditorialDisclaimer) return null;

  return <NWEditorialStandards type={disclaimerType} {...props} />;
};

EditorialStandards.fragment = gql`
  fragment EditorialStandards on Article {
    showEditorialDisclaimer
    disclaimerType: editorialDisclaimerType
  }
`;

export default EditorialStandards;
