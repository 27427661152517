import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import classes from '@nerdwallet/base-styles/classes';
import { Type } from '@nerdwallet/currency';
import StructuredContent from '@nerdwallet/structured-content';
import { gql } from '@nerdwallet/apollo';
import { MethodologyFragment } from '~/generated-gql/generated-types';

interface Props {
  article: MethodologyFragment;
}

const Methodology: React.GraphqlFC<Props> = ({ article }) => {
  const doc = article?.methodology?.content;

  if (_.isEmpty(doc)) {
    return null;
  }

  return (
    <div className={classes('type-1', 'margin-bottom-4')}>
      <Type bold className={classes('margin-bottom-2')}>
        Methodology
      </Type>
      <StructuredContent>{doc}</StructuredContent>
    </div>
  );
};

Methodology.fragment = gql`
  fragment Methodology on Article {
    methodology {
      content
    }
  }
`;

Methodology.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  article: PropTypes.object.isRequired,
};

export default Methodology;
