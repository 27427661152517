/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import _ from 'lodash';

import withJsonLd from '@nerdwallet/json-ld';
import { getArticleSchema } from '@nerdwallet/json-ld/mappings/wp/article';
import { getNerdlayoutDynamicOptions } from '@nerdwallet/wp-api/utils/meta-data';

import GlobalMarkupOptions from '@nerdwallet/nw-app-module/GlobalMarkupOptions';

import { Location } from 'history';
import {
  getArticleTypeConfig,
  getArticleCanonicalUrl,
} from '~/lib/article-utils';
import { appendEmbedJsScriptToDocument, renderEmbeds } from '~/lib/embed';
import { Experiments, getActiveExperiments } from '~/lib/ab';

import WpEditLink from '../../components/wp-edit-link/wp-edit-link';
import Breadcrumb from './breadcrumb';
import {
  ArticleFragmentFragment,
  GetArticleSchemaFragment,
} from '~/generated-gql/generated-types';
import { ReduxStore, RouterPathParams } from '~/lib/types';

type Props = RouteComponentProps<RouterPathParams> & {
  graphqlArticle: ArticleFragmentFragment;
  loading: boolean;
  location: Location;
  slug: string;
  experiments: Experiments;
  hasWpCookie: boolean;
};

// pull in SEO experimental schema
@withJsonLd(
  ({ graphqlArticle }: { graphqlArticle: ArticleFragmentFragment }) =>
    graphqlArticle?.seo?.extraJsonLd
)
@withJsonLd(
  ({ graphqlArticle }: { graphqlArticle: GetArticleSchemaFragment }) =>
    getArticleSchema(graphqlArticle)
)
class ArticleContainer extends Component<Props> {
  componentDidMount() {
    // Manually append embed.js since embed <script> tags in the WP
    // API response aren't parsed via dangerouslySetInnerHTML.
    appendEmbedJsScriptToDocument();
  }

  componentDidUpdate() {
    // Rerender embeds if the article is updated and fully loaded.
    if (!this.props.loading) {
      renderEmbeds();
    }
  }

  renderNlOpts() {
    const {
      graphqlArticle,
      location: { pathname },
    } = this.props;

    const canonicalURL = getArticleCanonicalUrl(graphqlArticle, pathname);
    const { contentClusters } = graphqlArticle;

    const optionsOverrides = {
      canonicalURL,
      contentClusters,
    };

    const dynamicOpts = getNerdlayoutDynamicOptions({
      post: graphqlArticle,
      optionsOverrides,
    });

    const globalNavOpts = {
      globalNavProps: {
        primaryNavCategory: 'Guides & Tips',
      },
    };

    const opts = _.merge(globalNavOpts, dynamicOpts);

    return (
      <React.Fragment>
        <Breadcrumb>{graphqlArticle}</Breadcrumb>
        <GlobalMarkupOptions>{opts}</GlobalMarkupOptions>
      </React.Fragment>
    );
  }

  renderArticleComponent() {
    const articleTypeConfig = getArticleTypeConfig(
      this.props.graphqlArticle?.articleType
    );
    const ArticleComponent = articleTypeConfig.component;

    const props = {
      slug: this.props.slug,
      graphqlArticle: this.props.graphqlArticle,
      loading: this.props.loading,
      activeExperiments: getActiveExperiments({
        experiments: this.props.experiments,
        location: this.props.location,
        articleType: this.props.graphqlArticle?.articleType,
      }),
    };

    _.assign(props, _.omit(articleTypeConfig, ['component']));

    return <ArticleComponent {...props} />;
  }

  render() {
    return (
      <div className="article-container">
        {this.renderNlOpts()}
        {this.props.hasWpCookie && (
          <WpEditLink id={this.props.graphqlArticle.id} />
        )}
        {this.renderArticleComponent()}
      </div>
    );
  }
}

// Ensure we only grab pieces of the state that we need.
const mapStateToProps = (state: ReduxStore) =>
  _.pick(state, ['hasWpCookie', 'experiments']);

export default withRouter(connect(mapStateToProps)(ArticleContainer));
