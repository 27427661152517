/* eslint-disable consistent-return */
import _ from 'lodash';
import semver from 'semver';
import slugify from 'slugify';

import migrate from '@nerdwallet/structured-content/migrations';
import schema from '@nerdwallet/structured-content/schema';
import walk, { transform } from '@nerdwallet/structured-content/tree-utils';
import {
  ANCHORLINKTARGET,
  CONTAINER,
  CONTENTHEADING,
  TABLEOFCONTENTS,
  TABLEOFCONTENTSTARGET,
  WPPOSTLIST,
} from '@nerdwallet/structured-content/types';
import hash from '@nerdwallet/structured-content/hash';
import {
  StructuredContentDoc,
  WpEntityType,
  StructuredContentSchema,
  SCChildren,
} from './types';

interface TocItem {
  target: string;
  title: SCChildren;
}

/**
 * Construct an "entity" whose shape matches the default WP entity shape.
 *
 * @param {Object} options
 * @param {Object} options.doc Structured content document.
 * @param {number} options.id Unique WP identifier.
 * @param {string} options.slug Human-readable identifier.
 * @param {string} options.type Canonical WP entity type (e.g. nw_house_ad).
 *
 * @todo Come up with a better way of handling/logging non-conforming
 *     entities (e.g. block group, house ad), than generating a "fake"
 *     entity because blocks don't fit the entity model nicely.
 *
 * @return {Object} Entity with shape matching default WP entity shape.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types -- directive added automatically by Shepherd migration
export const constructEntity = ({
  doc,
  id,
  slug,
  type,
}: {
  doc: StructuredContentDoc;
  id: number;
  slug: string;
  type: WpEntityType;
}) => ({
  content: {
    structured: doc,
  },
  id,
  slug,
  type,
});

export const extractNodeText = (node: StructuredContentDoc): string => {
  if (_.isNull(node) || _.isUndefined(node)) return;
  if (_.isNumber(node)) return `${node}`;
  if (_.isString(node)) return node;
  if (_.isArray(node)) {
    return _.reduce(node, (acc, val) => acc + extractNodeText(val), '');
  }
  if (!_.get(node, 'props.children')) return hash(node);
  return extractNodeText(_.get(node, 'props.children'));
};

const getTocTarget = (node: StructuredContentDoc) =>
  slugify(`${extractNodeText(node)}`.toLowerCase());

// export to be used in tests
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types -- directive added automatically by Shepherd migration
export const isLevel2ContentHeading = (node: StructuredContentDoc) =>
  node.type === CONTENTHEADING && node.props.level === 2;

const extractContentHeadingChildren = (node: StructuredContentDoc) => {
  let contentHeadingChild: SCChildren;

  walk(node, schema, (n: StructuredContentDoc | string) => {
    // ignore if it's already set, this way we only
    // save the children of the first h2 found
    if (contentHeadingChild) {
      return;
    }

    if (_.isObject(n) && isLevel2ContentHeading(n)) {
      contentHeadingChild = _.get(n, 'props.children');
    }
  });

  return contentHeadingChild;
};

const constructTocSchema = ({
  schema: sch,
  onNewItem,
}: {
  schema: StructuredContentSchema;
  onNewItem?: (target: string, children: SCChildren) => void;
}) => {
  const modifiedSchema = _.cloneDeep(sch);

  const componentMigrations = sch?.[CONTENTHEADING]?.migrations;
  const currentVersion = sch?.[CONTENTHEADING]?.version;
  const newVersion = semver.inc(currentVersion, 'major');

  _.set(modifiedSchema, `${CONTENTHEADING}.migrations`, {
    ...componentMigrations,
    [newVersion]: (node: StructuredContentDoc) => {
      const { type, props } = node;

      const newNode = {
        type,
        version: newVersion,
        props,
      };

      if (!isLevel2ContentHeading(node)) return newNode;
      const target = getTocTarget(node);

      if (onNewItem) {
        onNewItem(target, node.props.children);
      }

      return {
        type: ANCHORLINKTARGET,
        version: '1.0.0',
        props: {
          target,
          children: newNode,
        },
      };
    },
  });
  _.set(modifiedSchema, `${CONTENTHEADING}.version`, newVersion);
  return modifiedSchema;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types -- directive added automatically by Shepherd migration
export const constructTableOfContentsDocument = ({
  items,
  hasJumpCTA = false,
  hasItemMarker = false,
  showMore = false,
}: {
  items: Array<TocItem>;
  hasJumpCTA?: boolean;
  hasItemMarker?: boolean;
  showMore?: boolean;
}) => {
  const children: StructuredContentDoc | [] = items.length
    ? {
        type: TABLEOFCONTENTS,
        version: '1.0.0',
        props: {
          items,
          hasJumpCTA,
          hasItemMarker,
          showMore,
        },
      }
    : [];

  return {
    type: CONTAINER,
    version: '1.0.0',
    props: {
      children,
    },
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types -- directive added automatically by Shepherd migration
export const augmentWithToc = (doc: StructuredContentDoc) => {
  const items: Array<TocItem> = [];

  let modifiedDoc = migrate(doc, schema);

  walk(modifiedDoc, schema, (node: StructuredContentDoc) => {
    if (node.type === TABLEOFCONTENTSTARGET) {
      items.push({
        target: node.props.target,
        title: extractContentHeadingChildren(node),
      });
    }

    return node;
  });

  // need to modify schema if no TOCTarget is found for backwards compatibility
  if (!items.length) {
    // 1. construct new schema
    const tocSchema = constructTocSchema({
      schema,
      onNewItem: (target, title) => {
        items.push({
          target,
          title,
        });
      },
    });

    // 2. migrate doc with new schema
    modifiedDoc = migrate(modifiedDoc, tocSchema);
  }

  // return object of all things
  return {
    items,
    augmentedDoc: modifiedDoc,
  };
};

// exported for use in validation
export const TocSchema = constructTocSchema({ schema });

export function limitPostListSize(limit: number) {
  return (node: StructuredContentDoc) => {
    const ids = _.get(node, 'props.query.include');
    if (_.isArray(ids)) {
      _.set(node, 'props.query.include', ids.slice(0, limit));
    }

    _.set(node, 'props.query.per_page', limit);
    return node;
  };
}

export function excludePostListArticle(articleId: number) {
  return (node: StructuredContentDoc) => {
    const ids = _.get(node, 'props.query.include');
    if (_.isArray(ids)) {
      _.set(
        node,
        'props.query.include',
        ids.filter((id) => id !== articleId)
      );
    }

    _.set(node, 'props.query.exclude', articleId);
    return node;
  };
}

export function setPostListProps(props: { [k: string]: any }) {
  return (node: StructuredContentDoc) => {
    _.assign(node.props, props);
    return node;
  };
}

export type TransformWpPostListConfig = {
  limit?: number;
  excludeArticleId?: number;
  setProps?: { [k: string]: any };
};

export function transformWpPostList(
  doc: StructuredContentDoc,
  { limit, excludeArticleId, setProps }: TransformWpPostListConfig
) {
  // Construct the transform function by composing our set of individual transforms.
  // If an individual transform shouldn't be applied, use _.identity, which returns its input
  const transformFunction = _.flow(
    excludeArticleId ? excludePostListArticle(excludeArticleId) : _.identity,
    limit ? limitPostListSize(limit) : _.identity,
    setProps ? setPostListProps(setProps) : _.identity
  );
  const iteratee = (node: StructuredContentDoc) => {
    if (_.get(node, 'type') === WPPOSTLIST) {
      // eslint-disable-next-line no-param-reassign
      node = transformFunction(node);
    }

    return node;
  };
  transform(doc, schema, iteratee, { mutate: true });
}
