import React from 'react';
import _ from 'lodash';

import { gql } from '@nerdwallet/apollo';
import classes from '@nerdwallet/base-styles/classes';

import Block from './block';
import {
  transformWpPostList,
  TransformWpPostListConfig,
} from '~/lib/structured-content';
import { BlockGroupFragment } from '~/generated-gql/generated-types';

export interface Props {
  blockGroup: BlockGroupFragment;
  className?: string;
  wpPostListLimit?: number;
  template?: string;
  role?: string;
  templateConfig?: {
    [s: string]: any;
  };
  excludeArticleId?: number;
}

const BlockGroup: React.GraphqlFC<Props> = ({
  blockGroup,
  className,
  wpPostListLimit,
  template,
  role,
  templateConfig,
  excludeArticleId,
}) => {
  if (!blockGroup) {
    return null;
  }

  // Avoid modifying the `blockGroup` param, as the same blockGroup can be
  // passed in from different places which can result in unexpected behavior.
  // For reference: https://nerdwallet.atlassian.net/browse/AA-1592
  const clonedBlockGroup = _.cloneDeep(blockGroup);

  const { blocks } = clonedBlockGroup;
  if (_.isEmpty(blocks)) {
    return null;
  }

  const asideProps = {
    className,
    role,
  };

  const transformParams: TransformWpPostListConfig = { excludeArticleId };

  if (wpPostListLimit) {
    transformParams.limit = wpPostListLimit;
  }
  if (template) {
    _.set(transformParams, 'setProps.template', template);
  }
  if (templateConfig) {
    _.set(transformParams, 'setProps.templateConfig', templateConfig);
  }

  blocks.forEach((blk) => {
    const structuredData = blk.data;
    if (!_.isEmpty(structuredData)) {
      transformWpPostList(structuredData, transformParams);
    }
  });

  return (
    <aside {...asideProps}>
      {blocks.map((block, i) => (
        <Block
          key={block.title}
          block={block}
          className={
            i < blocks.length - 1 ? classes('margin-bottom-4') : undefined
          }
        />
      ))}
    </aside>
  );
};

BlockGroup.fragment = gql`
  fragment BlockGroup on BlockGroup {
    id
    blocks {
      ...block
    }
  }
  ${Block.fragment}
`;

export default BlockGroup;
