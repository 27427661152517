import { Html5Entities as Entities } from 'html-entities';

/**
 * Decode HTML entities.
 *
 * @param string String with encoded HTML entities.
 *
 * @return string String with HTML entities decoded.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types -- directive added automatically by Shepherd migration
export const decode = (string: string) =>
  // constructor is empty, so no need to memoize.
  new Entities().decode(string);

/**
 * Encode HTML entities.
 *
 * @param string String with decoded HTMl entities.
 *
 * @return string String with HTML entities encoded.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types -- directive added automatically by Shepherd migration
export const encode = (string: string) =>
  // constructor is empty, so no need to memoize.
  new Entities().encode(string);

/**
 * Replace old cdn assets. with new /assets.
 *
 * @param string Blob or single image src.
 *
 * @return string String with domain replaced.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types -- directive added automatically by Shepherd migration
export const cdnifyImagesInString = (string: string) =>
  typeof string === 'string'
    ? string
        .replace(/assets.nerdwallet.com/g, 'www.nerdwallet.com/assets')
        .replace(/cdn.nerdwallet.com/g, 'www.nerdwallet.com/cdn')
    : string;
