import React from 'react';
import { Link } from '@nerdwallet/currency';

interface Props {
  id: string;
}
const WpEditLink: React.FC<Props> = ({ id }) => {
  if (!id) {
    return null;
  }

  return (
    <Link
      href={`/blog/wp-admin/post.php?post=${id}&action=edit`}
      className="wp-edit-link"
      target="new"
    >
      Edit Article
    </Link>
  );
};

export default WpEditLink;
