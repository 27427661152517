import React from 'react';

import { gql } from '@nerdwallet/apollo';
import { AdvertiserDisclosure as NWAdvertiserDisclosure } from '@nerdwallet/currency';
import classes from '@nerdwallet/base-styles/classes';
import { AdvertiserDisclosureFragment } from '~/generated-gql/generated-types';

interface Props {
  article: AdvertiserDisclosureFragment;
}
const AdvertiserDisclosure: React.GraphqlFC<Props> = ({ article }) => {
  const { showAdvertiserDisclosure, disclosureType } = article;

  if (!showAdvertiserDisclosure) return null;

  return (
    <div className={classes('margin-vertical-2')}>
      <NWAdvertiserDisclosure type={disclosureType} />
    </div>
  );
};

AdvertiserDisclosure.fragment = gql`
  fragment AdvertiserDisclosure on Article {
    showAdvertiserDisclosure
    disclosureType: advertiserDisclosureType
  }
`;

export default AdvertiserDisclosure;
