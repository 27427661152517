import { combineReducers } from 'redux';

import { Reducer as experiments } from '@nerdwallet/nw-client-lib/ab';

import { reducer as impressions } from '@nerdwallet/nw-client-lib/tracking';

import hasWpCookie from './has-wp-cookie';

const combinedReducers = combineReducers({
  impressions,
  hasWpCookie,
  experiments,
});

export default combinedReducers;
