import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import classes from '@nerdwallet/base-styles/classes';
import { spacing } from '@nerdwallet/base-styles/variables';
import Image from '@nerdwallet/react-image';
import StructuredContent from '@nerdwallet/structured-content';
import { Title } from '@nerdwallet/currency';
import {
  Container as ABContainer,
  Variant,
  Control,
} from '@nerdwallet/nw-client-lib/ab';

import ABConfig from '~/lib/ab';
import ContentPageHeader from '~/client/components/content-page-header/content-page-header';
import EditorialStandards from '~/client/components/editorial-standards';
import { getMediaSrcSetGql, filterMediaSizes } from '~/lib/article-utils';
import ArticleStructuredContent from '~/client/components/article-structured-content/article-structured-content';
import {
  augmentWithToc,
  constructTableOfContentsDocument,
  TocSchema,
} from '~/lib/structured-content';

import AuthorBox from '~/client/components/author-box/author-box';
import Methodology from '~/client/components/methodology/methodology';
import ConnectedOutdatedPageNotification from '~/client/components/outdated-page-notification/outdated-page-notification';
import HouseAd from '~/client/components/house-ad/house-ad';
import NextSteps from '~/client/components/next-steps/next-steps';
import RelatedLinks from '~/client/components/related-links/related-links';

import styles from './general-article.module.less';
import FadeContainer from '../fade-container';
import { ArticleTemplateProps } from '~/lib/types';

const GeneralArticle: React.FC<ArticleTemplateProps> = ({
  hasTableOfContents,
  tableOfContentsHeading,
  graphqlArticle,
  activeExperiments,
}) => {
  const { items: tocItems, augmentedDoc } = augmentWithToc(
    graphqlArticle.content
  );

  const renderFeaturedImage = ({ variant = false } = {}) => {
    const featuredImageInfo =
      filterMediaSizes(graphqlArticle?.featuredImage, [
        'primary-l',
        'primary-m',
      ])?.mediaDetails?.sizes?.[0] ?? {};
    const featuredImageSrcSet = getMediaSrcSetGql(
      filterMediaSizes(graphqlArticle?.featuredImage, [
        'primary-xxxl',
        'primary-xxl',
        'primary-xl',
        'primary-l',
        'primary-m',
      ])
    );

    if (!featuredImageSrcSet) {
      return null;
    }

    return (
      <Image
        alt={graphqlArticle?.featuredImage?.altText}
        aspectRatio={variant ? '1:1' : '10:4'}
        className={classNames(
          classes('margin-bottom-4', 'margin-bottom-3--wide'),
          'article-header__image',
          { [styles.headerImageVariant]: variant }
        )}
        src={featuredImageInfo.sourceUrl}
        srcSet={featuredImageSrcSet}
      />
    );
  };

  // Renders AB component for any active experiments
  const renderABContainers = () => {
    return activeExperiments.map((activeExperiment) => {
      const testConfig = _.find(ABConfig, { id: activeExperiment.experiment });
      if (!testConfig) return null;
      return (
        <ABContainer experimentID={testConfig.id}>
          <Control />
          <Variant variantName={testConfig.variant} />
        </ABContainer>
      );
    });
  };

  return (
    <div className={styles.articleBoxContainer}>
      <div className={styles.articleBox}>
        {renderABContainers()}
        <div className={styles.columns}>
          <div className={classNames(styles['main-right'], styles['grid-10'])}>
            <div>
              <div
                className={classNames(
                  classes('margin-bottom-3', 'margin-top-3'),
                  styles['horizontal-padding--mobile']
                )}
              >
                <ContentPageHeader
                  article={graphqlArticle}
                  hideEditorialStandards
                  category={null}
                />
              </div>
              {!graphqlArticle.hideFeaturedImage && renderFeaturedImage()}
              {graphqlArticle.showEditorialDisclaimer && (
                <EditorialStandards
                  article={graphqlArticle}
                  className={classNames(
                    classes('margin-bottom-4'),
                    styles['horizontal-padding--mobile']
                  )}
                />
              )}
            </div>
            <div
              className={classNames(
                styles.content,
                styles['horizontal-padding--mobile']
              )}
            >
              <div className={styles['content-main']}>
                <ConnectedOutdatedPageNotification />
                {hasTableOfContents && tocItems.length ? (
                  <div className={classNames(styles['toc-wide-variant'])}>
                    <Title className={classes('margin-bottom-3')}>
                      {tableOfContentsHeading}
                    </Title>
                    <div className={styles.hideNotDesktop}>
                      <StructuredContent>
                        {constructTableOfContentsDocument({
                          items: tocItems,
                          hasJumpCTA: true,
                          showMore: true,
                        })}
                      </StructuredContent>
                    </div>
                    <div className={styles.hideDesktop}>
                      <StructuredContent>
                        {constructTableOfContentsDocument({
                          items: tocItems,
                          hasJumpCTA: true,
                        })}
                      </StructuredContent>
                    </div>
                  </div>
                ) : null}
                <main>
                  <div className={classes('margin-bottom-4')}>
                    {hasTableOfContents ? (
                      <ArticleStructuredContent schema={TocSchema}>
                        {augmentedDoc}
                      </ArticleStructuredContent>
                    ) : (
                      <ArticleStructuredContent>
                        {graphqlArticle.content}
                      </ArticleStructuredContent>
                    )}
                  </div>
                  <AuthorBox article={graphqlArticle} />
                  <Methodology article={graphqlArticle} />
                </main>
              </div>
              {RelatedLinks.shouldShow(graphqlArticle) && (
                <aside
                  className={classNames(
                    classes('position-relative'),
                    styles['right-sidebar']
                  )}
                  style={{ paddingTop: '14.5em' }}
                >
                  <FadeContainer offset={spacing['5']} fadeDistance={250}>
                    <RelatedLinks article={graphqlArticle} maxItems={4} />
                  </FadeContainer>
                </aside>
              )}
            </div>
          </div>
        </div>
        {NextSteps.shouldShow(graphqlArticle) && (
          <section className={classes(['margin-bottom-5', 'width-100'])}>
            <NextSteps article={graphqlArticle} />
          </section>
        )}
        <section className={styles.bottom}>
          {HouseAd.shouldShow(graphqlArticle) && (
            <div className={classes('margin-bottom-4')}>
              <HouseAd id={graphqlArticle.houseAd?.id} />
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

GeneralArticle.defaultProps = {
  activeExperiments: [],
};

export default GeneralArticle;
