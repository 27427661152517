import React from 'react';
import PropTypes from 'prop-types';

import useBreadcrumbs from '@nerdwallet/nw-app-module/useBreadcrumbs';
import { gql } from '@nerdwallet/apollo';

import { BreadcrumbFragment } from '~/generated-gql/generated-types';

interface Props {
  children: BreadcrumbFragment;
}

// HOC to call the useBreadcrumbs hook inside of class components
const Breadcrumb: React.GraphqlFC<Props> = ({ children }) => {
  const { breadcrumb } = children;

  useBreadcrumbs({
    breadcrumbSlug: breadcrumb?.slug,
    page: breadcrumb?.page,
  });

  return null;
};

Breadcrumb.fragment = gql`
  fragment Breadcrumb on Article {
    breadcrumb {
      slug
      page {
        title
      }
    }
  }
`;

Breadcrumb.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.object.isRequired,
};

export default Breadcrumb;
