export const ADD_HAS_WP_COOKIE = 'ADD_HAS_WP_COOKIE';

export interface AddHasWpCookieAction {
  type: typeof ADD_HAS_WP_COOKIE;
  data: boolean;
}

export const addHasWpCookie = (hasWpCookie = false): AddHasWpCookieAction => ({
  type: ADD_HAS_WP_COOKIE,
  data: hasWpCookie,
});
