/* eslint-disable react/destructuring-assignment */
/**
 * Dependencies
 */
import _ from 'lodash';
import classNames from 'classnames';
import DocumentTitle from 'react-document-title';
import React, { Fragment, Component } from 'react';

import classes from '@nerdwallet/base-styles/classes';
import LoadingN from '@nerdwallet/react-loading-n';
import { Impression } from '@nerdwallet/analytics/react/impression';
import { Location } from 'history';
import ArticleContainer from '../containers/article-container';
import { getUrlPath } from '../../lib/article-utils';

import { NotFound, RedirectWithStatusCode } from './status-code';
import withArticleQuery from './article-query';
import { ArticleFragmentFragment } from '~/generated-gql/generated-types';
import { ReactRouterChildrenProps } from '~/lib/types';

/**
 * Check whether the current article path matches the current RR
 * location object.
 *
 * Note: this is used to verify whether or not we should redirect from
 * our old URL structure to our new.
 *
 * @param {Object} article The query0 article.
 * @param {Object} locationObject The RR location object.
 *
 * @return {bool} Whether or not the article and location match.
 */
export const articlePathMatchesLocation = (
  article: ArticleFragmentFragment,
  locationObject: Location
) => {
  const articlePath = getUrlPath(article);
  const currentPath = _.get(locationObject, 'pathname');
  return articlePath === currentPath;
};

const loadingSpinner = (
  <div
    className={classNames([
      classes(['display-flex', 'justify-content-center', 'margin-vertical-5']),
    ])}
  >
    <LoadingN />
  </div>
);

const handlePageChange = () => {
  if (window) {
    // Ensure we scroll to the top of the page for any page transitions.
    window.scrollTo(0, 0);
  }
};

type Props = ReactRouterChildrenProps & {
  data: any;
};

export class BaseArticleRoute extends Component<Props> {
  prevSlug: string;

  static displayName = 'ArticleRoute';

  componentDidMount() {
    handlePageChange();
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps: Props) {
    const currentSlug = _.get(this.props, 'match.params.slug');
    const nextSlug = _.get(nextProps, 'match.params.slug');

    if (currentSlug !== nextSlug) {
      this.prevSlug = currentSlug;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types -- directive added automatically by Shepherd migration
  componentDidUpdate(prevProps: Props) {
    const currentSlug = _.get(this.props, 'match.params.slug');
    const prevSlug = _.get(prevProps, 'match.params.slug');

    if (currentSlug !== prevSlug) {
      handlePageChange();
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types -- directive added automatically by Shepherd migration
  getArticle() {
    const { data } = this.props;
    return data?.article ?? data?.articleFork;
  }

  renderArticle() {
    const article = this.getArticle();
    const location = _.get(this.props, 'location');
    const slug = _.get(this.props, 'match.params.slug');

    if (
      // article is done loading. Assume we have the article we most recently fetched.
      // Any 404 or error logic should be handled earlier
      !this.props.data?.loading &&
      // and path doesn't match article link doesn't match
      !articlePathMatchesLocation(article, location) &&
      // and published article
      article.isPublished &&
      // and not fork draft
      !this.props.data?.articleFork &&
      // we have a path to redirect to
      getUrlPath(article)
    ) {
      const redirectPath = getUrlPath(article);
      // Fallback to '' to ensure we don't output 'null'
      const redirectParams = location?.search ?? '';
      return (
        <RedirectWithStatusCode
          to={`${redirectPath}${redirectParams}`}
          code={301}
        />
      );
    }
    const children = (
      <Fragment>
        <DocumentTitle title={article.seo?.title} />
        <ArticleContainer
          graphqlArticle={article}
          slug={slug}
          loading={this.props.data?.loading ?? true}
        />
      </Fragment>
    );
    if (article?.id && article?.isPublished) {
      return (
        <Impression
          key={article.id}
          eventType="content_impression"
          payload={{
            content_id: Number(article.id),
            content_source: 'wp',
            content_id_type: 'post',
          }}
        >
          {children}
        </Impression>
      );
    }
    return children;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types -- directive added automatically by Shepherd migration
  render() {
    const gqlData = this.props.data ?? {};

    const { loading } = gqlData;
    const graphqlArticle = this.getArticle();

    const shouldBeLoading = loading && !graphqlArticle;
    const gqlError = gqlData?.error?.message ?? '';

    // non-404 error. Throw the error and return 500
    if (
      !loading &&
      (!graphqlArticle ||
        graphqlArticle.slug !== this.props.match?.params?.slug) &&
      gqlError &&
      gqlError.indexOf('404: Not Found') === -1
    ) {
      throw new Error(`Apollo error in ArticleRoute: ${gqlError}`);
    }

    if (!loading && !graphqlArticle) {
      return <NotFound />;

      // TODO-gql re-enable when the apollo state bug is solved
      // https://nerdwallet.atlassian.net/browse/FEI-1203
      // if (gqlError.indexOf('404: Not Found') !== -1) {
      //   return <NotFound />;
      // }
      // if (gqlError.indexOf('401: Unauthorized') !== -1) {
      //   return <Unauthorized />;
      // }
      // console.error(
      //   `Error during apollo query for ArticleRoute: ${gqlData?.error?.message}`
      // );
      // return <StatusCode code={500} />;
    }

    return (
      <div className="article-route-container">
        {/* Hack to deal with center-align bug: isolate loading spinner and article content.
        For more context, see https://nerdwallet.atlassian.net/browse/WPRM-209 */}
        <div>
          {shouldBeLoading ? loadingSpinner : null}
          {!shouldBeLoading ? this.renderArticle() : null}
        </div>
      </div>
    );
  }
}

export default withArticleQuery(BaseArticleRoute);
