import { ARTICLE_ROUTE_PATH } from './app-routes';

import { setWpLoggedInStatus } from '../../lib/server-request';
import { ReduxStore } from '~/lib/types';

const { BASE_URI } = process.env;

type DataDependencyArgs = {
  store?: ReduxStore;
  req?: Request;
  res?: Response;
  match?: string;
  location?: string;
};

/**
 * Define all server side data dependencies here. These requests will be run in series
 * before server side rendering. This ensures all dependency calls have been completed
 * before the hydrated store is passed to the render function.
 */
const dataDependencies = [
  {
    path: BASE_URI,
    loadData: (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { store, req, res, match, location }: DataDependencyArgs,
      callback: () => null
    ) => {
      callback();
    },
    routes: [
      {
        path: `${BASE_URI}${ARTICLE_ROUTE_PATH}`,
        loadData: (
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          { store, req, res, match, location }: DataDependencyArgs,
          callback: () => null
        ) => {
          // Set WP logged-in status flag.
          setWpLoggedInStatus(req, store);

          callback();
        },
      },
    ],
  },
];

export default dataDependencies;
