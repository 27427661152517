/* eslint-disable react/destructuring-assignment */
/**
 * This is a container for all route handlers. It can be simple and
 * dumb as is (passes route params down to RouteHandler),
 * but allows for an extra layer of control over all nested routes in needed.
 */

import React, { Component } from 'react';
import { Tracking as nwTracking } from '@nerdwallet/nw-client-lib';
import { connect } from 'react-redux';
import { DisplayPageContextProvider } from '@nerdwallet/structured-content/contexts/DisplayPageContext';
import { MarketplaceContextProvider } from '@nerdwallet/structured-content/contexts/MarketplaceContext';
import { MarketplaceProductCardContextProvider } from '@nerdwallet/structured-content/contexts/MarketplaceProductCardContext';
import { AnchorContextProvider } from '@nerdwallet/structured-content/contexts/AnchorContext';
import { BANKING_DISPLAY_PAGES } from '@nerdwallet/marketplace/constants';
import AppRoutes from './app-routes';
import { ReactRouterChildrenProps } from '~/lib/types';

class Container extends Component<ReactRouterChildrenProps> {
  static displayName = 'RouteContainer';

  componentDidMount() {
    // Fire logPageView on first load to get the previous path variable set up
    nwTracking.logPageView(this.props.location.pathname);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps: ReactRouterChildrenProps) {
    // Log the page view when the route changes.
    if (this.props.location.pathname !== nextProps.location.pathname) {
      // TODO: Move the error handling on nw-client-lib.
      nwTracking.logPageView(nextProps.location.pathname);
    }
  }

  render() {
    return (
      <DisplayPageContextProvider displayPage={BANKING_DISPLAY_PAGES.CONTENT}>
        <MarketplaceContextProvider>
          <AnchorContextProvider>
            <MarketplaceProductCardContextProvider
              props={{ isKetoCard: true }}
              ketoProps={{ forceMobile: true }}
            >
              <div className="route-container">
                <AppRoutes {...this.props} />
              </div>
            </MarketplaceProductCardContextProvider>
          </AnchorContextProvider>
        </MarketplaceContextProvider>
      </DisplayPageContextProvider>
    );
  }
}

export default connect((state) => state)(Container);
