/**
 * Route definitions. Entry point to all routes.
 */
import React from 'react';
import { Route, Switch } from 'react-router';
import { ReactRouterChildrenProps } from '~/lib/types';

import ArticleRoute from './article-route';
import { NotFound } from './status-code';

// Match the following:
//  1. Legacy URLs (/articles/{slug}) - these are redirected downstream.
//  2. URLs with variable-length path (/articles/{vertical}/{topic}/*/{slug}).
export const ARTICLE_ROUTE_PATH = '/:verticalPath*/:slug';

const AppRoutes: React.FC<ReactRouterChildrenProps> = (props) => {
  const currentMatchPath = props?.match?.path;

  return (
    <Switch>
      <Route path="*/null" component={NotFound} />
      <Route
        exact
        path={`${currentMatchPath}${ARTICLE_ROUTE_PATH}`}
        component={ArticleRoute}
      />
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default AppRoutes;
