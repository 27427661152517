import React from 'react';
import PropTypes from 'prop-types';

import classes from '@nerdwallet/base-styles/classes';
import { Link } from '@nerdwallet/currency';
import Twitter from '@nerdwallet/react-icon/social-regular/Twitter';
import Mail from '@nerdwallet/react-icon/social-regular/Mail';
import Facebook from '@nerdwallet/react-icon/social-regular/Facebook';

const SOCIAL_TYPES = {
  TWITTER: 'twitter',
  EMAIL: 'email',
  FACEBOOK: 'facebook',
};

const getSocialIcon = (type: string) => {
  if (type === SOCIAL_TYPES.TWITTER) {
    return <Twitter textAligned />;
  }
  if (type === SOCIAL_TYPES.EMAIL) {
    return <Mail textAligned />;
  }
  if (type === SOCIAL_TYPES.FACEBOOK) {
    return <Facebook textAligned />;
  }
  return null;
};

const getAriaLabel = (type: string, authorName: string) => {
  if (type === SOCIAL_TYPES.TWITTER) {
    return `Find ${authorName} on Twitter`;
  }
  if (type === SOCIAL_TYPES.EMAIL) {
    return `Email ${authorName}`;
  }
  if (type === SOCIAL_TYPES.FACEBOOK) {
    return `Find ${authorName} on Facebook`;
  }
  return null;
};

interface Props {
  authorName: string;
  mediaLinks: Array<{
    url: string;
    type: string;
  }>;
}

const SocialMediaLinks = ({ mediaLinks, authorName }: Props) => {
  const validSocialMediaLinks =
    mediaLinks &&
    mediaLinks.filter((linkProps) => linkProps.type && linkProps.url);

  return (
    validSocialMediaLinks &&
    validSocialMediaLinks.length > 0 && (
      <div>
        {validSocialMediaLinks.map((linkProps) => (
          <div
            key={linkProps.type}
            className={classes([
              'display-inline-block',
              'nowrap',
              'margin-right-3',
            ])}
          >
            <Link
              href={linkProps.url}
              target="_blank"
              rel="nofollow noopener noreferrer"
              className="author-card__social-icons"
              aria-label={getAriaLabel(linkProps.type, authorName)}
              unstyled
            >
              <span className={classes('vertical-align-middle')}>
                {getSocialIcon(linkProps.type)}
              </span>
            </Link>
          </div>
        ))}
      </div>
    )
  );
};

SocialMediaLinks.displayName = 'nw-social-media-links';

SocialMediaLinks.propTypes = {
  authorName: PropTypes.string.isRequired,
  mediaLinks: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    })
  ),
};

export default SocialMediaLinks;
