/* eslint-disable @typescript-eslint/no-use-before-define */
import _ from 'lodash';

import { Location } from 'history';
import { AbExperiment, ABTestEnum, ArticleTypes } from './types';

import { ARTICLE_TYPES } from './article-utils';
import { ArticleTypeEnum } from '~/generated-gql/generated-types';

const config: { [key in ABTestEnum]: AbExperiment } = {
  // This is an example and is not a real, active test
  [ABTestEnum.EXAMPLE_AB_TEST]: {
    id: 'articles_example_ab',
    control: 'control',
    variant: 'variant',
    urls: [
      {
        path: '/article/example-page',
        redirectUrl: '/blog/example-page/',
      },
    ],
  },
};

export default config;

/**
 * Returns experiment name for a given url path
 *
 * @param String path to find experiment name for
 *
 * @return String name of the experiment from EXPERIMENTS object
 */
export const getExperimentNameForPath = (path: string): ABTestEnum =>
  Object.keys(config).find((key: ABTestEnum) => {
    const experimentUrls = _.get(config, [key, 'urls'], []);
    return experimentUrls.some((urlObj) => _.includes(urlObj, path));
  }) as ABTestEnum; // TODO: Why do I have to cast here?

export type Experiments = {
  availableExperiments: {
    [key: string]: {
      variantName: string;
    };
  };
};
export const getVariantForTest = (
  experimentName: string,
  experiments: Experiments,
  search: string
) => {
  const searchParams = new URLSearchParams(search);
  return (
    searchParams.get(`test_${experimentName}`) ||
    experiments?.availableExperiments?.[experimentName]?.variantName ||
    'control'
  );
};

/**
 * Returns an array of active experiments.
 * Filters for experiments active for a given article type and pathname
 */
export const getActiveExperiments = (args: {
  experiments: Experiments;
  location: Location;
  articleType: ArticleTypeEnum;
}) => {
  return getActiveExperimentsImpl({
    ...args,
    articleTypes: ARTICLE_TYPES,
    abTestConfig: config,
  });
};

export type ActiveExperiments = Array<{
  experiment: string;
  variant: string;
}>;

// exported for testing
const getActiveExperimentsImpl = ({
  experiments,
  location,
  articleType,
  articleTypes,
  abTestConfig,
}: {
  experiments: Experiments;
  location: Location;
  articleType: ArticleTypeEnum;
  articleTypes: ArticleTypes;
  abTestConfig: {
    [s in ABTestEnum]: AbExperiment;
  };
}) => {
  // get experiments for article type
  const abConfig = articleTypes[articleType]?.abTestConfig ?? [];

  const activeExperiments: ActiveExperiments = [];

  abConfig.forEach((configString) => {
    const configObj = abTestConfig[configString];
    // skip if experiment is restricted to paths and this article isn't one of them
    if (configObj.paths && !configObj.paths.includes(location.pathname)) return;
    activeExperiments.push({
      experiment: configObj.id,
      variant: getVariantForTest(configObj.id, experiments, location.search),
    });
  });

  return activeExperiments;
};

export const experiments = Object.keys(config).map((testConstant) =>
  _.get(config, [testConstant, 'id'])
);

// eslint-disable-next-line no-underscore-dangle
export const __testing = {
  getActiveExperimentsImpl,
};
