import _ from 'lodash';

/**
 * Get the embed.js script URL for a specific environment.
 *
 * Note: these values could easily be added to app-configs, however
 * this should really only be a temporary solution until embed.js is
 * more widely supported in React-land, and ideally we'll pull all
 * this out sooner rather than later.
 *
 * @return {string} Embed.js URL.
 */
export const getEmbedJsScriptUrl = (env = process.env.CONFIG_ENV): string => {
  switch (env) {
    case 'development':
      return `${process.env.SITE_BASE_URL}/blog/wp-content/mu-plugins/nerdwallet-embeds/js/embed.js`;
    case 'staging':
      return 'https://embeds.nerdwallet.biz/embed.js';
    case 'production':
    default:
      return 'https://embeds.nerdwallet.com/embed.js';
  }
};

/**
 * Append our embed.js script to the current document.
 */
export const appendEmbedJsScriptToDocument = (): void => {
  if (!document) {
    return;
  }

  const src = getEmbedJsScriptUrl();
  const existingScript = document.querySelector(`script[src='${src}']`);

  if (existingScript) {
    return;
  }

  const script = document.createElement('script');
  script.src = src;
  document.body.appendChild(script);
};

/**
 * Render all embeds.
 */
export const renderEmbeds = (): void => {
  if (_.get(window, 'NerdWallet.Embeds.renderEmbeds')) {
    (window as any).NerdWallet.Embeds.renderEmbeds();
  }
};
