import { RouteChildrenProps } from 'react-router';
import {
  ArticleFragmentFragment,
  ArticleTypeEnum,
} from '~/generated-gql/generated-types';
import { ActiveExperiments } from './ab';

export interface AbExperiment {
  id: string;
  control: string;
  variant: string;
  /** For tests where control is a blog page and variant is an article-client page */
  urls?: Array<{
    path: string;
    redirectUrl: string;
  }>;
  /**
   * Limits test to executing on only these paths.
   * Ex: /article/credit-cards/the-best-credit-card
   */
  paths?: Array<string>;
}

export interface StructuredContentDoc {
  type: string;
  version: string;
  props: StructuredContentDocProps;
}

export interface StructuredContentDocProps {
  [s: string]: any;
  children?: SCChildren;
}

export type SCChildren =
  | StructuredContentDoc
  | Array<StructuredContentDoc | string | number>
  | string
  | number;

// todo-ts: move this to SC
export interface StructuredContentSchema {
  [s: string]: {
    nodeProps: Array<string>;
    version: string;
    migrations: {
      [v: string]: (
        props: StructuredContentDocProps
      ) => StructuredContentDocProps;
    };
  };
}

// todo-ts: type this
export type ReduxStore = any;

// todo-ts: type this
export type ServerRequest = any;

// todo-ts: type this
export type WpApiQuery = any;

// todo-ts: type this
export type WpEntityType = string;

export type RouterPathParams = {
  slug: string;
};

export type ReactRouterChildrenProps = RouteChildrenProps<RouterPathParams>;

export enum ABTestEnum {
  EXAMPLE_AB_TEST = 'EXAMPLE_AB_TEST',
}

export type ArticleTemplateProps = {
  slug: string;
  graphqlArticle: ArticleFragmentFragment;
  loading: boolean;
  activeExperiments: ActiveExperiments;
} & Omit<ArticleTypeConfig, 'component'>;

export type ArticleTypeConfig = {
  component: React.ComponentType<ArticleTemplateProps>;
  relatedContent?: boolean;
  articleTypeLabel?: string;
  hasTableOfContents?: boolean;
  hasHouseAds?: boolean;
  hasRelatedLinks?: boolean;
  hasNextSteps?: boolean;
  tableOfContentsHeading?: string;
  abTestConfig?: Array<ABTestEnum>;
};

export type ArticleTypes = {
  [key in ArticleTypeEnum]: ArticleTypeConfig;
};
