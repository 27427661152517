import React from 'react';
import { OUTOFDATEMESSAGE } from '@nerdwallet/structured-content/types';
import StructuredContent from '@nerdwallet/structured-content';

// eslint-disable-next-line @typescript-eslint/ban-types -- directive added automatically by Shepherd migration
export const OutdatedPageNotification: React.FC<{}> = () => {
  const OODDocument = {
    type: OUTOFDATEMESSAGE,
    version: '1.0.0',
    props: {
      spacing: {
        bottom: 3,
      },
    },
  };

  return <StructuredContent noClearFix>{OODDocument}</StructuredContent>;
};

export default OutdatedPageNotification;
