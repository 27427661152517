import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import initBaseStyles from '@nerdwallet/base-styles/init';
import '@nerdwallet/base-styles/reset.css';
import '@nerdwallet/base-styles/base.css';
import { initBrowserEvents } from '@nerdwallet/analytics';

import routeDefinitions from './routes';
import './styles/main.less';

initBaseStyles();
initBrowserEvents();
if (typeof __SENTRY_VERSION__ !== 'undefined') {
  // Require this here so we don't see a handful of console warnings related to finding sourcemaps
  /* eslint-disable global-require, @typescript-eslint/no-var-requires */
  require('@nerdwallet/sentry').initSentryBrowser({
    version: __SENTRY_VERSION__,
  });
  /* eslint-enable global-require, @typescript-eslint/no-var-requires */
}

export default () => <Router>{routeDefinitions}</Router>;
