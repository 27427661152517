import _ from 'lodash';
// functional version of lodash
// see: https://github.com/lodash/lodash/wiki/FP-Guide
import __ from 'lodash/fp';
import gql from 'graphql-tag';
import urlParse from 'url-parse';

import { PRODUCT_TYPES } from '@nerdwallet/nw-api-sdk/marketplace';
import { getMethodology } from '@nerdwallet/wp-api/selectors/common';
import appConfig from '@nerdwallet/app-config';

import {
  getDateCreatedAt,
  getDateUpdatedAt,
  getDateLastIngestedAt,
  getProductType,
} from '@nerdwallet/marketplace/selectors/common';

import { getDateMostRecentIso } from '@nerdwallet/wp-api/utils/date';

import GeneralArticle from '~/client/components/general-article/general-article';

import experimentConfig, { getExperimentNameForPath } from './ab';
import { ARTICLE_TYPE } from './constants/wp';
import {
  GetAbTestConfigFragment,
  ArticleTypeEnum,
  GetArticleCanonicalUrlFragment,
  ArticleFragmentFragment,
  MethodologyFragment,
  MediaItem,
  MediaSize,
} from '~/generated-gql/generated-types';
import { ArticleTypes, ArticleTypeConfig } from './types';

export const DEFAULT_ARTICLE_TYPE = ARTICLE_TYPE.GENERAL_ANSWER;

export const ARTICLE_TYPES: ArticleTypes = {
  [ArticleTypeEnum.StepByStep]: {
    component: GeneralArticle,
    relatedContent: false,
    hasTableOfContents: true,
    hasHouseAds: true,
    hasRelatedLinks: true,
    hasNextSteps: true,
    tableOfContentsHeading: 'Steps',
    abTestConfig: [],
  },
  [ArticleTypeEnum.GeneralAnswer]: {
    component: GeneralArticle,
    relatedContent: false,
    hasTableOfContents: false,
    hasHouseAds: true,
    hasRelatedLinks: true,
    hasNextSteps: true,
    abTestConfig: [],
  },
  [ArticleTypeEnum.Listicle]: {
    component: GeneralArticle,
    relatedContent: false,
    hasTableOfContents: false,
    hasHouseAds: true,
    hasRelatedLinks: true,
    hasNextSteps: true,
    abTestConfig: [],
  },
  [ArticleTypeEnum.TableOfContents]: {
    component: GeneralArticle,
    relatedContent: false,
    hasTableOfContents: true,
    hasHouseAds: true,
    hasRelatedLinks: true,
    hasNextSteps: true,
    tableOfContentsHeading: 'What’s inside',
    abTestConfig: [],
  },
};

/**
 * Gets the AB config object for a given article, based on the `abTestConfig` key in
 * `ARTICLE_TYPES` config
 * @deprecated
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types -- directive added automatically by Shepherd migration
export const getAbTestConfig = (article: GetAbTestConfigFragment) => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const articleTypeConfig = getArticleTypeConfig(article.articleType);
  const { abTestConfig = [] } = articleTypeConfig;
  return abTestConfig.map((configId) => experimentConfig[configId]);
};
getAbTestConfig.fragment = gql`
  fragment getAbTestConfig on Article {
    articleType
  }
`;

/**
 * Get article canonical URL.
 *
 * @param {Object} article Article.
 *
 * @return {string} Article canonical URL.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types -- directive added automatically by Shepherd migration
export const getArticleCanonicalUrl = (
  article: GetArticleCanonicalUrlFragment,
  path = ''
) => {
  // if it's currently in an experiment, use the /blog URL as the canonical
  const experimentName = getExperimentNameForPath(path);
  const experimentUrls = _.get(experimentConfig, [experimentName, 'urls']);
  const urlMatch = experimentUrls && _.find(experimentUrls, { path });
  const wpUrl =
    urlMatch && `${appConfig.SITE_BASE_URL}${_.get(urlMatch, 'redirectUrl')}`;

  // otherwise, get the canonical from the article object
  return wpUrl || article?.link;
};
getArticleCanonicalUrl.fragment = gql`
  fragment getArticleCanonicalUrl on Article {
    link
  }
`;

/**
 * Get config for a given article type.
 *
 * @param {string} articleType Article type.
 *
 * @return {Object} Config for the requested article type.
 */
export const getArticleTypeConfig = (
  articleType: string
): ArticleTypeConfig => {
  return _.get(ARTICLE_TYPES, articleType, ARTICLE_TYPES[DEFAULT_ARTICLE_TYPE]);
};

/**
 * Get the URL path for the article.
 *
 * e.g. /article/credit-cards/my-article
 *
 * @param {Object} article query0 article for which to get the URL path.
 *
 * @returns {Integer} URL path.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types -- directive added automatically by Shepherd migration
export const getUrlPath = (article: ArticleFragmentFragment) =>
  article.link ? urlParse(article.link)?.pathname : '';

/**
 * Get flag for showing methodology section. Encapsulates logic for
 * deciding whether to render methodology section or not
 *
 * @param  {Object} article WP article
 *
 * @return {Boolean} Whether to show methodology section
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types -- directive added automatically by Shepherd migration
export const shouldShowMethodology = (article: MethodologyFragment) =>
  !_.isEmpty(getMethodology(article));

/**
 * Determines which date to show given a date, some options, and the
 * marketplace entities that exist on the page alongside it
 *
 * @param {Object} args
 * @param {Date|String} args.displayDate the calculated display date of the entity
 * @param {Boolean} args.showLastModifiedDate whether or not to show the last modified date
 * @param {Boolean} args.hideDate whether or not to hide the date
 * @param {Object[]} args.marketplaceEntities marketplace entities on the page
 *
 * @return {String} The date to display in string format
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types -- directive added automatically by Shepherd migration
export const computeDisplayedDate = ({
  displayDate,
  showLastModifiedDate,
  hideDate,
  marketplaceEntities,
}: {
  displayDate: Date | string;
  showLastModifiedDate: boolean;
  hideDate: boolean;
  marketplaceEntities: Array<any>;
}) => {
  let computedDate = displayDate;

  if (showLastModifiedDate && !hideDate) {
    // get most recently modified date from all marketplace entities
    const compareDates = [displayDate];
    _.each(marketplaceEntities, (entity) => {
      const productType = getProductType(entity);
      // Only modify header date for CC products.
      if (productType !== PRODUCT_TYPES.CREDIT_CARDS) return;

      compareDates.push(getDateCreatedAt(entity));
      compareDates.push(getDateUpdatedAt(entity));
      compareDates.push(getDateLastIngestedAt(entity));
    });
    computedDate = getDateMostRecentIso(compareDates);
  }

  return computedDate;
};

/**
 * Get a srcSet containing all the images at the same ratio of a given image size.
 *
 * @param   {Object} mediaItem  query0 MediaItem object.
 *
 * @return  {String}            `srcSet` string.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types -- directive added automatically by Shepherd migration
export const getMediaSrcSetGql = (
  mediaItem: Pick<MediaItem, 'mediaDetails'>
) => {
  const sources: Array<string> = [];

  __.flow([
    // get the size list
    __.getOr([], 'mediaDetails.sizes'),
    // sort by image widths
    __.sortBy((s: MediaSize) => s.width),
    // add size to source list
    __.each((currSize: MediaSize) => {
      const srcUrl = currSize?.sourceUrl;
      const width = currSize?.width;
      sources.push(`${srcUrl} ${width}w`);
    }),
  ])(mediaItem);

  return sources.join(', ');
};

/**
 * Return a MediaItem object with sizes filtered by a given array of size labels
 *
 * @param   {Object} mediaItem  query0 MediaItem object.
 * @param   {Array} sizes       Array of media sizes to return
 *
 * @return  {String}            `srcSet` string.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types -- directive added automatically by Shepherd migration
export const filterMediaSizes = (
  mediaItem?: Pick<MediaItem, 'mediaDetails'>,
  sizes: Array<string> = []
) => {
  if (!mediaItem?.mediaDetails?.sizes?.length) return mediaItem;
  return {
    ...mediaItem,
    mediaDetails: {
      ...mediaItem.mediaDetails,
      sizes: sizes
        .map((size) => {
          return _.find(mediaItem?.mediaDetails?.sizes, { size });
        })
        .filter(Boolean),
    },
  };
};
