import {
  ArticleTypeEnum,
  ArticleLayoutEnum,
} from '~/generated-gql/generated-types';

export const ARTICLE_LAYOUT: {
  [key: string]: ArticleLayoutEnum;
} = {
  DEFAULT: ArticleLayoutEnum.Default,
  FULL: ArticleLayoutEnum.Full,
};

export const ARTICLE_TYPE = {
  STEP_BY_STEP: ArticleTypeEnum.StepByStep,
  GENERAL_ANSWER: ArticleTypeEnum.GeneralAnswer,
  LISTICLE: ArticleTypeEnum.Listicle,
  TABLE_OF_CONTENTS: ArticleTypeEnum.TableOfContents,
};
