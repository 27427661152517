import { ADD_HAS_WP_COOKIE, AddHasWpCookieAction } from '../actions';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types -- directive added automatically by Shepherd migration
export default (
  state: Record<string, unknown> | null = null,
  action: AddHasWpCookieAction
) => {
  switch (action.type) {
    case ADD_HAS_WP_COOKIE:
      return action.data;
    default:
      return state;
  }
};
