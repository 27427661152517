/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import StructuredContent from '@nerdwallet/structured-content';
import {
  ACCORDION,
  CONTENTIMAGE,
  CONTENTQUOTE,
  LINK,
  MARKETPLACEPRODUCTCARD,
  PULL,
  WPEMBED,
} from '@nerdwallet/structured-content/types';
import { TocSchema } from '../../../lib/structured-content';
import styles from './article-structured-content.module.less';

function renderNode(node, Component) {
  const { type, props } = node;
  switch (type) {
    case PULL:
    case CONTENTQUOTE: {
      // PULL and CONTENTQUOTE are similar and need negative margins.
      const classnames = classNames(
        styles.pull,
        styles[`pull__align-${props.align || 'none'}`]
      );
      return <Component {...props} className={classnames} />;
    }
    case CONTENTIMAGE: {
      const classnames = classNames(
        styles.pull,
        styles.pull__image,
        styles[`pull__align-${props.align || 'none'}`]
      );
      return <Component {...props} className={classnames} />;
    }
    case ACCORDION: {
      const classnames = classNames(styles.accordion);
      return <Component {...props} className={classnames} />;
    }
    case LINK:
      // render all structured links as 'decorated' to match
      //  current styles for article-client html content
      return <Component {...props} className={styles.link} />;
    case WPEMBED: {
      const isCff = props.src.indexOf('/cff/') !== -1;
      return (
        <Component
          {...props}
          className={classNames(
            // eslint-disable-next-line react/destructuring-assignment
            props.className,
            styles.embed,
            {
              [styles['is-cff']]: isCff,
            }
          )}
        />
      );
    }
    case MARKETPLACEPRODUCTCARD: {
      return (
        <div className={styles.ketoCardWrapper}>
          <Component {...props} />
        </div>
      );
    }
    default:
      break;
  }
  return undefined;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types -- directive added automatically by Shepherd migration
const ArticleStructuredContent = ({ children, ...otherProps }) => {
  if (!children) return null;

  return (
    <StructuredContent renderNode={renderNode} {...otherProps}>
      {children}
    </StructuredContent>
  );
};

ArticleStructuredContent.propTypes = {
  /**
   * Structured-content document (JSON) to render
   */
  children: PropTypes.shape({
    type: PropTypes.string.isRequired,
    props: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  }).isRequired,
};

export default ArticleStructuredContent;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types -- directive added automatically by Shepherd migration
export const TocSchemaProvider = ({ children }) => (
  <StructuredContent.Provider schema={TocSchema}>
    {children}
  </StructuredContent.Provider>
);
