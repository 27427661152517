import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { gql } from '@nerdwallet/apollo';
import { Type } from '@nerdwallet/currency';

import ArticleStructuredContent from '../article-structured-content/article-structured-content';
import styles from './block-group.module.less';
import { BlockFragment } from '~/generated-gql/generated-types';

interface Props {
  block?: BlockFragment;
  className?: string;
}

const Block: React.GraphqlFC<Props> = ({ block, className }) => {
  if (!block || !block?.data) {
    return null;
  }

  const { title, data: structuredData } = block;

  return (
    <div className={className}>
      {title && (
        <Type
          component="h5"
          className={classNames(
            'block-group__block__title',
            styles.block__title
          )}
        >
          {title}
        </Type>
      )}
      <ArticleStructuredContent>{structuredData}</ArticleStructuredContent>
    </div>
  );
};

Block.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  block: PropTypes.object.isRequired,
  className: PropTypes.string,
};

Block.fragment = gql`
  fragment block on CombinedBlockGroupBlock {
    type
    title
    data
  }
`;

export default Block;
