import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from '@nerdwallet/base-styles/classes';
import { Link, Type } from '@nerdwallet/currency';
import SocialMediaLinks from './social-media-links';

interface Author {
  name: string;
  heading: string;
  bio: string;
  fullPageUrl: string;
  socialMediaLinks: Array<{
    url: string;
    type: string;
  }>;
}

interface Props extends Author {
  className: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types -- directive added automatically by Shepherd migration
const AuthorCard = ({
  name,
  heading,
  bio,
  fullPageUrl,
  socialMediaLinks,
  className,
}: Props) => {
  if (!name) {
    return null;
  }

  return (
    <div
      className={classNames([
        'author-card',
        className,
        classes(['display-flex', 'flex-direction-column']),
      ])}
    >
      <div className={classes('padding-bottom-1')}>
        {heading && (
          <Type size={1} bold inline>
            {heading}{' '}
          </Type>
        )}
        {bio && (
          <Type size={1} className="author-card__bio" inline italic>
            {bio}
            {fullPageUrl && (
              <Link
                href={fullPageUrl}
                className={classNames([classes(['nowrap'])])}
              >
                {' '}
                Read more
              </Link>
            )}
          </Type>
        )}
      </div>
      <SocialMediaLinks mediaLinks={socialMediaLinks} authorName={name} />
    </div>
  );
};

AuthorCard.displayName = 'nw-author-card';

AuthorCard.propTypes = {
  name: PropTypes.string.isRequired,
  heading: PropTypes.string,
  bio: PropTypes.string,
  fullPageUrl: PropTypes.string,
  socialMediaLinks: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  className: PropTypes.string,
};

export default AuthorCard;
