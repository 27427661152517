import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import MarketplaceContext from '@nerdwallet/structured-content/contexts/MarketplaceContext';

import { gql } from '@nerdwallet/apollo';
import WordPressContentPageHeader from '@nerdwallet/react-wordpress-content-page-header';

import { computeDisplayedDate } from '~/lib/article-utils';
import { ContentPageHeaderFragment } from '~/generated-gql/generated-types';

interface Props {
  article: ContentPageHeaderFragment;
  showAdvertiserDisclosure?: boolean;
  hideEditorialStandards?: boolean;
  category?: string;
}

const ContentPageHeader: React.GraphqlFC<Props> = ({
  article,
  showAdvertiserDisclosure,
  ...props
}) => {
  const { entities: marketplaceEntities = [] } = useContext(MarketplaceContext);

  const {
    dateTimeDisplayDate: displayDate,
    hideDate,
    showLastModifiedDate,
    showAdvertiserDisclosure: articleShowAD,
    advertiserDisclosureType,
  } = article;

  const computedDate = computeDisplayedDate({
    displayDate,
    hideDate,
    showLastModifiedDate,
    marketplaceEntities,
  });

  return (
    <WordPressContentPageHeader
      authoringDate={computedDate}
      wordPressEntity={article}
      advertiserDisclosureShouldDelay={false}
      hideAdvertiserDisclosure={!(articleShowAD && showAdvertiserDisclosure)}
      advertiserDisclosureType={advertiserDisclosureType}
      {...props}
    />
  );
};

ContentPageHeader.fragment = gql`
  fragment ContentPageHeader on Article {
    hideDate
    showLastModifiedDate
    ...WordPressContentPageHeader
  }
  ${WordPressContentPageHeader.fragment}
`;

ContentPageHeader.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  article: PropTypes.object.isRequired,
  showAdvertiserDisclosure: PropTypes.bool,
  ...WordPressContentPageHeader.propTypes,
};

ContentPageHeader.defaultProps = {
  showAdvertiserDisclosure: true,
};

export default ContentPageHeader;
