/**
 * Route definitions. Entry point to all routes.
 */

import React from 'react';
import { Route } from 'react-router';

import appConfig from '@nerdwallet/app-config';

import Container from './container';
import dataDependencies from './data-dependencies';

const baseUri = appConfig.BASE_URI;

const routes = (
  <Route
    dataDependencies={dataDependencies}
    path={baseUri}
    render={({ match, location, history }) => (
      <Container match={match} history={history} location={location} />
    )}
  />
);

export default routes;
