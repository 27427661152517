import React from 'react';
import _ from 'lodash';

import { gql } from '@nerdwallet/apollo';
import StructuredContent from '@nerdwallet/structured-content';

import { getArticleTypeConfig } from '~/lib/article-utils';

/**
 * Construct a WPHouseAd structured content doc from an ID.
 *
 * @param {string} id House ad ID.
 *
 * @return {Object} WP house ad structured content doc.
 */
const buildStructuredContentDoc = (id: string) => ({
  type: 'Container',
  version: '1.0.0',
  props: {
    children: [
      {
        type: 'WPHouseAd',
        version: '1.0.0',
        props: {
          id,
        },
      },
    ],
  },
});

interface Props {
  id: string;
}

const HouseAd: React.GraphqlFC<Props> = ({ id }) => {
  if (!id || _.isNaN(id)) {
    return null;
  }

  return <StructuredContent>{buildStructuredContentDoc(id)}</StructuredContent>;
};

HouseAd.fragment = gql`
  fragment HouseAd on Article {
    articleType
    houseAd {
      id
    }
  }
`;

HouseAd.shouldShow = (article) => {
  const articleTypeConfig = getArticleTypeConfig(article?.articleType);
  const houseAd = article?.houseAd;
  return _.get(articleTypeConfig, 'hasHouseAds', false) && !!houseAd;
};

export default HouseAd;
