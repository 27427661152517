import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import { gql } from '@nerdwallet/apollo';
import baseStyles from '@nerdwallet/base-styles/classes';
import { Link, Title, Type } from '@nerdwallet/currency';
import ArrowForward from '@nerdwallet/react-icon/material/ArrowForward';
import { ElementImpression } from '@nerdwallet/nw-client-lib/tracking';
import { Impression as BedrockImpression } from '@nerdwallet/analytics/react/impression';
import {
  setTrk,
  TrkTag,
  TrkElement,
  TrkPageType,
} from '@nerdwallet/nw-client-lib/trk-helper';

import WP_POST_LIMITS from '~/lib/constants/wp-post-limits';
import { getArticleTypeConfig } from '~/lib/article-utils';
import BlockGroup from '../block-group/block-group';

import styles from './next-steps.module.less';
import { NextStepsFragment } from '~/generated-gql/generated-types';

const CATEGORY_LINK_TEXT = 'Explore';

interface Props {
  article: NextStepsFragment;
}

const NextSteps: React.GraphqlFC<Props> = ({ article }) => {
  const { nextSteps: blockGroup, primaryCategory } = article;
  const { name, link } = primaryCategory || {};

  const href = setTrk({
    url: link,
    params: {
      [TrkTag.element]: TrkElement.hyperlink,
      [TrkTag.location]: 'NextSteps',
      [TrkTag.pageType]: TrkPageType.article,
      [TrkTag.copy]: `${CATEGORY_LINK_TEXT} ${name}`,
    },
  });

  return (
    <ElementImpression
      id="article-client-next-steps"
      elementName="NextSteps"
      sectionCategory="belowContent"
    >
      <BedrockImpression
        eventType="content_element_impression"
        entityName="next_steps"
        payload={{
          content_source: 'wp',
          content_id: parseInt(blockGroup.id, 10),
          content_id_type: 'post',
        }}
      >
        <div className={baseStyles('border-top-2', 'border-green')}>
          <Title
            size="x-small"
            className={classNames(
              baseStyles('margin-vertical-3'),
              styles['horizontal-padding--mobile']
            )}
          >
            Dive even deeper in {name}
          </Title>
          <BlockGroup
            blockGroup={blockGroup}
            className={baseStyles('margin-bottom-2')}
            wpPostListLimit={WP_POST_LIMITS.NEXT_STEPS}
            excludeArticleId={parseInt(article.id, 10)}
          />
          <div
            className={classNames(
              baseStyles('text-align-right'),
              styles['horizontal-padding--mobile']
            )}
          >
            <Link unstyled href={href} className={styles.hover}>
              <Type inline size={1} uppercase color="blue">
                <Type inline color="neutral-darkest">
                  {CATEGORY_LINK_TEXT}
                </Type>{' '}
                <Type inline bold className={styles.underline}>
                  {name}
                </Type>{' '}
                <ArrowForward textAligned />
              </Type>
            </Link>
          </div>
        </div>
      </BedrockImpression>
    </ElementImpression>
  );
};

NextSteps.fragment = gql`
  fragment NextSteps on Article {
    id
    articleType
    nextSteps {
      ...BlockGroup
    }
    primaryCategory {
      name
      link
    }
  }
  ${BlockGroup.fragment}
`;

NextSteps.shouldShow = (article) => {
  const articleTypeConfig = getArticleTypeConfig(article?.articleType);
  const blockGroup = article?.nextSteps;
  return _.get(articleTypeConfig, 'hasNextSteps', false) && !!blockGroup;
};

export default NextSteps;
